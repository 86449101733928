.comparison {
    width: 100%;
    background-color: #88bfa2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.comparison-wrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
}

.comparison-flex {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.comparison-mainLogo {
    height: 87px;
    width: 290px;
    margin: 0 auto;
}

.comparison-flex-wrapper {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    margin: 10px auto;
    align-items: center;
}

.comparison-flex-wrapper-inside {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
}

.comparison-flex-wrapper-inside p:first-child {
    line-height: 1.09091;
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
}

.comparison-flex-wrapper-inside p:last-child {
    font-size: 14px;
    margin-top: 10px;
}

.block-icon {
    float: left;
    width: 64px;
    height: 64px;
    border: 2px solid #ffce2b;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 4px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.icon-inside {
    width: 100%;
    height: 100%;
    background: #fabd00;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.icon-inside img {
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
}

.comparison-flex-p1 {
    color: #4f4f4e;
    text-align: center;
    text-transform: uppercase;
    font: 32px/50px 'Arial';
    margin-top: 20px;
}

.comparison-flex-p2 {
    color: #4f4f4e;
    font: 26px/50px 'Arial';
    margin-top: 5px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: -0.3px;
}

.comparison-flex-p3 {
    color: #7b7668;
    font: 14px 'Open Sans';
    font-weight: 300;
    margin-top: 5px;
}

.comparison-flex-p3:last-child {
    margin-top: 30px;
}

.comparison-flex:last-child {
    width: 35%;
}

.Line-wrapper {
    float: left;
    padding: 20px 0;
}

a img {
    height: 20px;
    width: 20px;
}

a {
    color: #ff5d00;
    text-decoration: none;
    transition: .3s all ease;
    margin: 0 auto;
    padding: 10px 0;
    vertical-align: center;
}

.link-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-bottom: 50px;
}

@media screen and (max-width: 850px){
    .comparison-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .comparison-flex{
        width: 90%;
    }
    .comparison-flex:last-child{
        width: 90%;
    }
    .comparison-mainLogo{
        width: 200px;
        height: 50px;
        margin-top: 15px;
    }
    .comparison-flex-p1 {
        font: 22px/25px 'Arial';
        font-weight: bold;
    }

    .comparison-flex-p2 {
        font: 20px/25px 'Arial';
    }
    .block-icon {
        width: 34px;
        height: 34px;
    }
    .icon-inside {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .icon-inside img {
        vertical-align: middle;
        display: inline-block;
        width: 16px;
        height: 16px;
    }
}
