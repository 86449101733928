.call {
  height: 25vh;
  background-image: url("../../img/call/bg-image-5.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.call-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.call-wrapper-p {
  color: #fff;
  font: 40px Montserrat, Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 500px) {
  .call-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .call-wrapper-p{
    font-size: 30px;
  }
}
