.benefits {
  width: 100%;
  background-color: #192028;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  align-items: center;
}

.benefits-p {
  text-transform: uppercase;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 65px;
  margin-top: 100px;
  text-align: center;
}

.benefits-p strong {
  color: #e65400;
}

.benefits-wrapper {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 80px;
  padding-bottom: 140px;
}

.benefits-wrapper-mobile {
  display: none;
}

.benefits-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.benefits-flex-info {
  display: flex;
  justify-content: flex-start;
  width: 40%;
  align-items: center;
}

.benefits-flex-info img {
  height: 64px;
  width: 64px;
}

.benefits-flex-info-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;
  align-items: center;
  text-align: center;
}

.benefits-flex-info-column p:first-child {
  line-height: 1.09091;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.04em;
  padding: 10px;
}

.benefits-flex-info-column p:last-child {
  font-size: 14px;
  width: 244px;
  text-align: left;
  color: #fff;
}

@media screen and (max-width: 1030px) {
  .benefits-flex-info-column p:first-child {
    line-height: 1.09091;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.04em;
    padding: 10px;
  }

  .benefits-flex-info-column p:last-child {
    font-size: 10px;
    width: 150px;
    text-align: left;
    color: #fff;
  }
}

@media screen and (max-width: 850px) {
  .benefits-center-img {
    height: 290px;
    width: 295px;
  }
  .benefits-p {
    font-size: 35px;
  }
  .benefits-flex-info-column p:first-child {
    font-size: 10px;
  }
  .benefits-flex-info img {
    height: 26px;
    width: 26px;
  }
  .benefits-flex-info-column p:last-child {
    width: 100px;
  }
}

@media screen and (max-width: 600px) {
  .benefits-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 80px;
    padding-bottom: 140px;
  }
  .benefits-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .benefits-flex-info {
    padding-bottom: 10px;
  }
}
