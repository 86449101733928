@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300&display=swap);
* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.header-main {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8) !important;
  padding: 15px 0;
  /*position: fixed;*/
  width: 100%;

  display: flex;
  justify-content: space-around;

  align-items: center;
  text-align: center;
}

.header {
  height: 100%;

  align-items: center;
  text-align: center;

  display: flex;
}

.header-main-logo {
  height: 60px;
  width: 60px;
}

.header-left-p {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #fff;
  text-transform: uppercase;
  padding-left: 10px;
}

.header-img-phone {
  height: 30px;
  width: 30px;
}

.header-right-p a {
  margin-left: 20px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  color: white;
  text-decoration: none;
}

.header-right-p a:hover {
  color: #ffd172;
  text-decoration: none;
  transition: 0.3s all ease;
}

.btn-ml-20 {
  margin-left: 20px;
}

@media screen and (max-width: 1020px) {
  .header a Button {
    width: 100px;
    font-size: 11px;
  }
}

@media (max-width: 925px) {
  .header-main-logo {
    height: 30px;
    width: 30px;
  }
  .header-left-p {
    font-size: 18px;
  }
  .header-img-phone {
    display: none;
  }

  .header-right-p a {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .header-right-p {
    display: none;
  }
  .btn-ml-20 {
    margin-left: 0;
  }
}

.button {
    background: none repeat scroll 0 0 #ff5d00;
    font-family: Arial;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    padding: 15px 5px;
    text-transform: uppercase;
    border-radius: 14px;
    letter-spacing: .02em;
    transition: .3s ease-out;
    border: #ff5d00;
    /* margin-left: 20px; */
    cursor: pointer;
    align-items: center;
    text-align: center;
}

.success-message{
    font-family: Arial;
    font-size: 14px;
    color: #7fbf00;
    margin: 10px 0;
}

.main_page-main {
  height: 100vh;
  width: 100%;
  background-image: url(/static/media/header_bg.11d59f15.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main_page-wrapper {
  width: 70%;
  height: 60%;

  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.main_page-left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.main_page-right {
  width: 40%;
  height: 80%;
}

.main_page-p1 {
  color: #fffaec;
  font: 85px/85px "Arial";
  text-transform: uppercase;
  padding-left: 8px;
  font-weight: bold;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 80%);
  text-align: left;
}

.main_page-p2 {
  color: #f2ce49;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 80%);
  line-height: 1.16667;
  font-size: 30px;
  text-transform: uppercase;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-top: 110px;
  /*margin-left: 160px;*/
}

.toastify {
  position: absolute;
  right: 0;
  top: 50%;
}

.form-wrapper {
  background: #fff;
  align-items: center;
  width: 100%;
  border-radius: 7px;
  padding: 32px 30px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.form-p {
  color: #555555;
  font: 22px/24px "Arial";
  text-transform: uppercase;
  text-align: center;
}

.form-p:nth-child(even) {
  font-size: 40px;
  color: #7fbf00;
  text-align: center;
  margin-top: 10px;
}

.form-p-small {
  font: 14px/14px "Open Sans";
  color: #1b0600;
  text-align: center;
  margin-top: 10px;
  text-transform: lowercase;
}

@media (max-width: 1320px) {
  .main_page-p1 {
    font-size: 60px;
  }
}

@media (max-width: 925px) {
  .main_page-p1 {
    font-size: 45px;
  }
  .main_page-wrapper {
    width: 95%;
  }
}

@media (max-width: 1320px) {
  .form-p:nth-child(even) {
    font-size: 25px;
  }
  .form-p {
    font: 16px/18px "Arial";
  }
}

@media screen and (max-width: 925px) {
  .form-wrapper {
    width: 300px;
  }
}

@media screen and (max-width: 753px) {
  .form-wrapper {
    width: 250px;
  }
}

@media screen and (max-width: 620px) {
  .form-wrapper {
    display: none;
  }
}

@media (max-width: 500px) {
  .main_page-p1 {
    text-align: center;
  }
  .main_page-left {
    width: 100%;
    align-items: center;
  }
  .main_page-right {
    display: none;
  }
  .main_page-p2 {
    display: none;
  }
}



.form-p-small-error {
  font: 14px/14px "Open Sans";
  color: red;
  text-align: center;
  margin-top: 10px;
  text-transform: lowercase;
}

.form-wrapper Button {
  margin-top: 30px;
}

.input {
  width: 100%;
  height: 43px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  padding: 0 45px 0 14px;
  border-radius: 7px;
  margin-top: 15px;
  color: #1b0600;
  font: 14px "Open Sans";
}



.offer {
  width: 100%;
  background-color: #f2ce49;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.offer-p {
  line-height: 1;
  font: 65px Montserrat, Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: bold;
  margin-top: 50px;
}

.offer-data {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin: 90px auto;
}

.offer-data-div {
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.offer-data-div img {
  height: 96px;
  width: 96px;
}

.offer-data-div h5 {
  font: 25px Montserrat, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #ffffff;
  margin-top: 30px;
}

.offer-data-div p {
  color: #fff;
  font: 20px Robot, Helvetica, Arial sans-serif;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (max-width: 850px) {
  .offer-p {
    font-size: 35px;
  }
  .offer-data-div img {
    height: 56px;
    width: 56px;
  }

  .offer-data-div h5 {
    font: 20px Montserrat, Helvetica, Arial, sans-serif;
    font-weight: bold;
  }

  .offer-data-div p {
    font: 15px Robot, Helvetica, Arial sans-serif;
  }
}

@media screen and (max-width: 500px) {
  .offer-p {
    text-align: center;
  }
  .offer-data-div {
    width: 100%;
  }
  .offer-title {
    font-size: 14px !important;
  }
}

.benefits {
  width: 100%;
  background-color: #192028;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  align-items: center;
}

.benefits-p {
  text-transform: uppercase;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 65px;
  margin-top: 100px;
  text-align: center;
}

.benefits-p strong {
  color: #e65400;
}

.benefits-wrapper {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 80px;
  padding-bottom: 140px;
}

.benefits-wrapper-mobile {
  display: none;
}

.benefits-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.benefits-flex-info {
  display: flex;
  justify-content: flex-start;
  width: 40%;
  align-items: center;
}

.benefits-flex-info img {
  height: 64px;
  width: 64px;
}

.benefits-flex-info-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;
  align-items: center;
  text-align: center;
}

.benefits-flex-info-column p:first-child {
  line-height: 1.09091;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.04em;
  padding: 10px;
}

.benefits-flex-info-column p:last-child {
  font-size: 14px;
  width: 244px;
  text-align: left;
  color: #fff;
}

@media screen and (max-width: 1030px) {
  .benefits-flex-info-column p:first-child {
    line-height: 1.09091;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.04em;
    padding: 10px;
  }

  .benefits-flex-info-column p:last-child {
    font-size: 10px;
    width: 150px;
    text-align: left;
    color: #fff;
  }
}

@media screen and (max-width: 850px) {
  .benefits-center-img {
    height: 290px;
    width: 295px;
  }
  .benefits-p {
    font-size: 35px;
  }
  .benefits-flex-info-column p:first-child {
    font-size: 10px;
  }
  .benefits-flex-info img {
    height: 26px;
    width: 26px;
  }
  .benefits-flex-info-column p:last-child {
    width: 100px;
  }
}

@media screen and (max-width: 600px) {
  .benefits-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 80px;
    padding-bottom: 140px;
  }
  .benefits-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .benefits-flex-info {
    padding-bottom: 10px;
  }
}

.usefull {
  width: 100%;
  background-image: url(/static/media/bg-image-6.52807d3e.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.usefull-wrapper {
  width: 75%;
  margin: 0 auto;
  display: flex;
  padding: 100px 0 100px 0;
  justify-content: space-around;
}

.usefull-flex {
  margin-top: 50px;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.usefull-circle {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  background-color: #ff5d00;
  border-color: #ff5d00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.usefull-circle img {
  height: 48px;
  width: 48px;
}

.usefull-flex-p-title {
  color: #fff;
  font: 18px Montserrat, Helvetica, Arial, sans-serif;
  margin: 20px 0px 0px 0px;
  font-weight: bold;
  text-align: center;
}

.usefull-flex p:last-child {
  color: #fff;
  font: 16px Roboto, Helvetica, Arial sans-serif;
  margin: 33px 0px 50px 0px;
  text-align: left;
  line-height: 30px;
}

.usefull-p {
  text-transform: uppercase;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 65px;
  margin-top: 100px;
  text-align: center;
}

.usefull-p strong {
  color: #e65400;
}

@media screen and (max-width: 850px) {
  .usefull-circle {
    height: 56px;
    width: 56px;
  }

  .usefull-circle img {
    height: 28px;
    width: 28px;
  }

  .usefull-flex-p-title {
    font: 14px Montserrat, Helvetica, Arial, sans-serif;
    font-weight: bold;
  }

  .usefull-flex p:last-child {
    font: 13px Roboto, Helvetica, Arial sans-serif;
  }
  .usefull-p {
    font-size: 26px;
  }
}

@media screen and (max-width: 500px) {
  .usefull-wrapper {
    flex-direction: column;
  }
  .usefull-flex {
    margin: 0;
    width: 100%;
  }
}

.comparison {
    width: 100%;
    background-color: #88bfa2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.comparison-wrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
}

.comparison-flex {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.comparison-mainLogo {
    height: 87px;
    width: 290px;
    margin: 0 auto;
}

.comparison-flex-wrapper {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    margin: 10px auto;
    align-items: center;
}

.comparison-flex-wrapper-inside {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
}

.comparison-flex-wrapper-inside p:first-child {
    line-height: 1.09091;
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
}

.comparison-flex-wrapper-inside p:last-child {
    font-size: 14px;
    margin-top: 10px;
}

.block-icon {
    float: left;
    width: 64px;
    height: 64px;
    border: 2px solid #ffce2b;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 4px;
    transition: all 0.5s;
}

.icon-inside {
    width: 100%;
    height: 100%;
    background: #fabd00;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    transition: all 0.5s;
}

.icon-inside img {
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
}

.comparison-flex-p1 {
    color: #4f4f4e;
    text-align: center;
    text-transform: uppercase;
    font: 32px/50px 'Arial';
    margin-top: 20px;
}

.comparison-flex-p2 {
    color: #4f4f4e;
    font: 26px/50px 'Arial';
    margin-top: 5px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: -0.3px;
}

.comparison-flex-p3 {
    color: #7b7668;
    font: 14px 'Open Sans';
    font-weight: 300;
    margin-top: 5px;
}

.comparison-flex-p3:last-child {
    margin-top: 30px;
}

.comparison-flex:last-child {
    width: 35%;
}

.Line-wrapper {
    float: left;
    padding: 20px 0;
}

a img {
    height: 20px;
    width: 20px;
}

a {
    color: #ff5d00;
    text-decoration: none;
    transition: .3s all ease;
    margin: 0 auto;
    padding: 10px 0;
    vertical-align: center;
}

.link-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-bottom: 50px;
}

@media screen and (max-width: 850px){
    .comparison-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .comparison-flex{
        width: 90%;
    }
    .comparison-flex:last-child{
        width: 90%;
    }
    .comparison-mainLogo{
        width: 200px;
        height: 50px;
        margin-top: 15px;
    }
    .comparison-flex-p1 {
        font: 22px/25px 'Arial';
        font-weight: bold;
    }

    .comparison-flex-p2 {
        font: 20px/25px 'Arial';
    }
    .block-icon {
        width: 34px;
        height: 34px;
    }
    .icon-inside {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .icon-inside img {
        vertical-align: middle;
        display: inline-block;
        width: 16px;
        height: 16px;
    }
}

.line-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
}

.line {
    font: 14px/30px 'Open Sans';
    padding: 0 15px;
    color: #fffaec;
    float: left;
    margin-right: 10px;
    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
    -o-transition: all 500ms linear;
    -ms-transition: all 500ms linear;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
}

.number {
    color: #2e0000;
    font: 14px/30px 'Open Sans';
    font-weight: 600;
}

@media screen and (max-width: 850px){
    .number{
        font: 14px 'Open Sans';
        font-weight: 600;
    }
}


.call {
  height: 25vh;
  background-image: url(/static/media/bg-image-5.eb03ef0b.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.call-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.call-wrapper-p {
  color: #fff;
  font: 40px Montserrat, Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 500px) {
  .call-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .call-wrapper-p{
    font-size: 30px;
  }
}

.call_you {
    background-color: #FFFFFF;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
}

.call_you-p:first-child {
    text-transform: uppercase;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 50px;
    margin-top: 50px;
}

.call_you-p:last-child {
    font: 20px Roboto, Helvetica, Arial, sans-serif;
    margin: 100px 0 0 0;
    color: #414141;
    line-height: 2;
}

.call_you-wrapper {
    width: 50%;
    margin: 0 auto;
}

.call_you-wrapper Button {
    margin: 20px 0;
}

@media screen and (max-width: 850px) {
    .call_you-p:first-child{
        font-size: 35px;
        text-align: center;
    }
}

:root{--primary-color: #ff5d00;--secondary-color: #FFB489}.container{display:flex;align-items:center;justify-content:center;margin:20px 0}.tabs{display:flex;position:relative;background-color:#fff;padding:.75rem;border-radius:99px}.tabs *{z-index:2}input[type=radio]{display:none}.tab{display:flex;align-items:center;justify-content:center;height:54px;width:250px;font-size:1.25rem;font-weight:500;border-radius:99px;cursor:pointer;transition:color .15s ease-in}.notification{display:flex;align-items:center;justify-content:center;width:2rem;height:2rem;margin-left:.75rem;border-radius:50%;background-color:#FFB489;background-color:var(--secondary-color);transition:.15s ease-in}input[type=radio]:checked+label{color:#ff5d00;color:var(--primary-color)}input[type=radio]:checked+label>.notification{background-color:#ff5d00;background-color:var(--primary-color);color:#fff}input[id=radio-1]:checked~.glider{-webkit-transform:translateX(0);transform:translateX(0)}input[id=radio-2]:checked~.glider{-webkit-transform:translateX(100%);transform:translateX(100%)}.glider{position:absolute;display:flex;height:54px;width:250px;background-color:#FFB489;background-color:var(--secondary-color);z-index:1;border-radius:99px;transition:.25s ease-out}@media(max-width: 700px){.tabs{-webkit-transform:scale(0.6);transform:scale(0.6)}}
.contacts {
  background-color: #2c2c2c;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contacts-wrapper {
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.contacts-wrapper-inside {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.contacts-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contacts-row a:hover {
  color: #ffd172;
  text-decoration: none;
  transition: 0.3s all ease;
}

.contacts-p1 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  line-height: 1.8;
  white-space: nowrap;
  margin-left: -100%;
  margin-right: -100%;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  padding: 6px;
}

.contacts-row img {
  height: 20px;
  width: 20px;
}

.contacts-row a {
  line-height: 1.09091;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-left: 5px;
}

.contacts-p2 {
  font-size: 14px;
  color: #ffffff;
  padding: 6px;
}

.contacts-p3 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding: 30px 0;
}

@media screen and (max-width: 1055px) {
  .contacts-row a {
    font-size: 14px;
    font-weight: bold;
  }

  .contacts-p1 {
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .contacts-wrapper {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .contacts-wrapper-inside:nth-child(2) {
    flex-direction: row;
    width: 88%;
  }
  .contacts-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (max-width: 500px) {
  .contacts-wrapper-inside {
    width: 80%;
  }
}

