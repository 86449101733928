

.form-p-small-error {
  font: 14px/14px "Open Sans";
  color: red;
  text-align: center;
  margin-top: 10px;
  text-transform: lowercase;
}

.form-wrapper Button {
  margin-top: 30px;
}

.input {
  width: 100%;
  height: 43px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  padding: 0 45px 0 14px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  margin-top: 15px;
  color: #1b0600;
  font: 14px "Open Sans";
}


