.usefull {
  width: 100%;
  background-image: url("../../img/usefull/bg-image-6.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.usefull-wrapper {
  width: 75%;
  margin: 0 auto;
  display: flex;
  padding: 100px 0 100px 0;
  justify-content: space-around;
}

.usefull-flex {
  margin-top: 50px;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.usefull-circle {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  background-color: #ff5d00;
  border-color: #ff5d00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.usefull-circle img {
  height: 48px;
  width: 48px;
}

.usefull-flex-p-title {
  color: #fff;
  font: 18px Montserrat, Helvetica, Arial, sans-serif;
  margin: 20px 0px 0px 0px;
  font-weight: bold;
  text-align: center;
}

.usefull-flex p:last-child {
  color: #fff;
  font: 16px Roboto, Helvetica, Arial sans-serif;
  margin: 33px 0px 50px 0px;
  text-align: left;
  line-height: 30px;
}

.usefull-p {
  text-transform: uppercase;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 65px;
  margin-top: 100px;
  text-align: center;
}

.usefull-p strong {
  color: #e65400;
}

@media screen and (max-width: 850px) {
  .usefull-circle {
    height: 56px;
    width: 56px;
  }

  .usefull-circle img {
    height: 28px;
    width: 28px;
  }

  .usefull-flex-p-title {
    font: 14px Montserrat, Helvetica, Arial, sans-serif;
    font-weight: bold;
  }

  .usefull-flex p:last-child {
    font: 13px Roboto, Helvetica, Arial sans-serif;
  }
  .usefull-p {
    font-size: 26px;
  }
}

@media screen and (max-width: 500px) {
  .usefull-wrapper {
    flex-direction: column;
  }
  .usefull-flex {
    margin: 0;
    width: 100%;
  }
}
