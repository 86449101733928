.line-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
}

.line {
    font: 14px/30px 'Open Sans';
    padding: 0 15px;
    color: #fffaec;
    float: left;
    margin-right: 10px;
    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
    -o-transition: all 500ms linear;
    -ms-transition: all 500ms linear;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
}

.number {
    color: #2e0000;
    font: 14px/30px 'Open Sans';
    font-weight: 600;
}

@media screen and (max-width: 850px){
    .number{
        font: 14px 'Open Sans';
        font-weight: 600;
    }
}

