.contacts {
  background-color: #2c2c2c;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contacts-wrapper {
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.contacts-wrapper-inside {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.contacts-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contacts-row a:hover {
  color: #ffd172;
  text-decoration: none;
  transition: 0.3s all ease;
}

.contacts-p1 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  line-height: 1.8;
  white-space: nowrap;
  margin-left: -100%;
  margin-right: -100%;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  padding: 6px;
}

.contacts-row img {
  height: 20px;
  width: 20px;
}

.contacts-row a {
  line-height: 1.09091;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-left: 5px;
}

.contacts-p2 {
  font-size: 14px;
  color: #ffffff;
  padding: 6px;
}

.contacts-p3 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding: 30px 0;
}

@media screen and (max-width: 1055px) {
  .contacts-row a {
    font-size: 14px;
    font-weight: bold;
  }

  .contacts-p1 {
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .contacts-wrapper {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .contacts-wrapper-inside:nth-child(2) {
    flex-direction: row;
    width: 88%;
  }
  .contacts-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (max-width: 500px) {
  .contacts-wrapper-inside {
    width: 80%;
  }
}
