.call_you {
    background-color: #FFFFFF;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
}

.call_you-p:first-child {
    text-transform: uppercase;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 50px;
    margin-top: 50px;
}

.call_you-p:last-child {
    font: 20px Roboto, Helvetica, Arial, sans-serif;
    margin: 100px 0 0 0;
    color: #414141;
    line-height: 2;
}

.call_you-wrapper {
    width: 50%;
    margin: 0 auto;
}

.call_you-wrapper Button {
    margin: 20px 0;
}

@media screen and (max-width: 850px) {
    .call_you-p:first-child{
        font-size: 35px;
        text-align: center;
    }
}
