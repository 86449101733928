.offer {
  width: 100%;
  background-color: #f2ce49;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.offer-p {
  line-height: 1;
  font: 65px Montserrat, Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: bold;
  margin-top: 50px;
}

.offer-data {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin: 90px auto;
}

.offer-data-div {
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.offer-data-div img {
  height: 96px;
  width: 96px;
}

.offer-data-div h5 {
  font: 25px Montserrat, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #ffffff;
  margin-top: 30px;
}

.offer-data-div p {
  color: #fff;
  font: 20px Robot, Helvetica, Arial sans-serif;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (max-width: 850px) {
  .offer-p {
    font-size: 35px;
  }
  .offer-data-div img {
    height: 56px;
    width: 56px;
  }

  .offer-data-div h5 {
    font: 20px Montserrat, Helvetica, Arial, sans-serif;
    font-weight: bold;
  }

  .offer-data-div p {
    font: 15px Robot, Helvetica, Arial sans-serif;
  }
}

@media screen and (max-width: 500px) {
  .offer-p {
    text-align: center;
  }
  .offer-data-div {
    width: 100%;
  }
  .offer-title {
    font-size: 14px !important;
  }
}
