:root {
  --primary-color: #ff5d00;
  --secondary-color: #FFB489;
}


.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  //box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
  padding: 0.75rem;
  border-radius: 99px; // just a high number to create pill effect
  * {
    z-index: 2;
  }
}

input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 250px;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 99px; // just a high number to create pill effect
  cursor: pointer;
  transition: color 0.15s ease-in;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-left: 0.75rem;
  border-radius: 50%;
  background-color: var(--secondary-color);
  transition: 0.15s ease-in;
}

input[type="radio"] {
  &:checked {
    & + label {
      color: var(--primary-color);
      & > .notification {
        background-color: var(--primary-color);
        color: #fff;
      }
    }
  }
}

input[id="radio-1"] {
  &:checked {
    & ~ .glider {
      transform: translateX(0);
    }
  }
}

input[id="radio-2"] {
  &:checked {
    & ~ .glider {
      transform: translateX(100%);
    }
  }
}


.glider {
  position: absolute;
  display: flex;
  height: 54px;
  width: 250px;
  background-color: var(--secondary-color);
  z-index: 1;
  border-radius: 99px; // just a high number to create pill effect
  transition: 0.25s ease-out;
}

@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }
}
