.header-main {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8) !important;
  padding: 15px 0;
  /*position: fixed;*/
  width: 100%;

  display: flex;
  justify-content: space-around;

  align-items: center;
  text-align: center;
}

.header {
  height: 100%;

  align-items: center;
  text-align: center;

  display: flex;
}

.header-main-logo {
  height: 60px;
  width: 60px;
}

.header-left-p {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #fff;
  text-transform: uppercase;
  padding-left: 10px;
}

.header-img-phone {
  height: 30px;
  width: 30px;
}

.header-right-p a {
  margin-left: 20px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  color: white;
  text-decoration: none;
}

.header-right-p a:hover {
  color: #ffd172;
  text-decoration: none;
  transition: 0.3s all ease;
}

.btn-ml-20 {
  margin-left: 20px;
}

@media screen and (max-width: 1020px) {
  .header a Button {
    width: 100px;
    font-size: 11px;
  }
}

@media (max-width: 925px) {
  .header-main-logo {
    height: 30px;
    width: 30px;
  }
  .header-left-p {
    font-size: 18px;
  }
  .header-img-phone {
    display: none;
  }

  .header-right-p a {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .header-right-p {
    display: none;
  }
  .btn-ml-20 {
    margin-left: 0;
  }
}
