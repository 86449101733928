.main_page-main {
  height: 100vh;
  width: 100%;
  background-image: url("../../img/main_page/header_bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main_page-wrapper {
  width: 70%;
  height: 60%;

  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.main_page-left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.main_page-right {
  width: 40%;
  height: 80%;
}

.main_page-p1 {
  color: #fffaec;
  font: 85px/85px "Arial";
  text-transform: uppercase;
  padding-left: 8px;
  font-weight: bold;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 80%);
  text-align: left;
}

.main_page-p2 {
  color: #f2ce49;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 80%);
  line-height: 1.16667;
  font-size: 30px;
  text-transform: uppercase;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-top: 110px;
  /*margin-left: 160px;*/
}

.toastify {
  position: absolute;
  right: 0;
  top: 50%;
}

.form-wrapper {
  background: #fff;
  align-items: center;
  width: 100%;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  padding: 32px 30px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.form-p {
  color: #555555;
  font: 22px/24px "Arial";
  text-transform: uppercase;
  text-align: center;
}

.form-p:nth-child(even) {
  font-size: 40px;
  color: #7fbf00;
  text-align: center;
  margin-top: 10px;
}

.form-p-small {
  font: 14px/14px "Open Sans";
  color: #1b0600;
  text-align: center;
  margin-top: 10px;
  text-transform: lowercase;
}

@media (max-width: 1320px) {
  .main_page-p1 {
    font-size: 60px;
  }
}

@media (max-width: 925px) {
  .main_page-p1 {
    font-size: 45px;
  }
  .main_page-wrapper {
    width: 95%;
  }
}

@media (max-width: 1320px) {
  .form-p:nth-child(even) {
    font-size: 25px;
  }
  .form-p {
    font: 16px/18px "Arial";
  }
}

@media screen and (max-width: 925px) {
  .form-wrapper {
    width: 300px;
  }
}

@media screen and (max-width: 753px) {
  .form-wrapper {
    width: 250px;
  }
}

@media screen and (max-width: 620px) {
  .form-wrapper {
    display: none;
  }
}

@media (max-width: 500px) {
  .main_page-p1 {
    text-align: center;
  }
  .main_page-left {
    width: 100%;
    align-items: center;
  }
  .main_page-right {
    display: none;
  }
  .main_page-p2 {
    display: none;
  }
}
