.button {
    background: none repeat scroll 0 0 #ff5d00;
    font-family: Arial;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    padding: 15px 5px;
    text-transform: uppercase;
    border-radius: 14px;
    letter-spacing: .02em;
    transition: .3s ease-out;
    border: #ff5d00;
    /* margin-left: 20px; */
    cursor: pointer;
    align-items: center;
    text-align: center;
}

.success-message{
    font-family: Arial;
    font-size: 14px;
    color: #7fbf00;
    margin: 10px 0;
}
